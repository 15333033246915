// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// 3rd party
import LocalTime from "local-time";
LocalTime.start();
// global.Trix = require("trix");
require("trix");
require("@rails/actiontext");

import "controllers"
import "stylesheets/application"

// Imports
document.addEventListener("turbolinks:load", function (e) {
	// Begin Mobile Small devices menubar
	// var toggle = document.getElementById("nav-toggler");
	// var topNav = document.getElementById("navigation");

	// // Listen for click event on toggle var
	// toggle.addEventListener(
	// 	"click",
	// 	function () {
	// 		// console.log("toggle clicked");
	// 		// toggle class "hidden" on topNav var
	// 		topNav.classList.toggle("hidden");
	// 	},
	// 	false
	// );
	// End Mobile Small devices menubar
	
	// Begin open all links inside trix-content in new window/tab
	var tcwEle = document.getElementById("trix-content-wrapper");
	if(tcwEle){
		var a = document.getElementById('trix-content-wrapper').getElementsByTagName('a');
        	for (var i=0; i<a.length; i++){
            	a[i].setAttribute('target', '_blank');
        	}
	}
	// End open all links inside trix-content in new window/tab
});